/* Componenti/Carosello.css */

/* Contenitore del carosello */
.carosello-container {
    max-width: 900px; /* Puoi modificare questa dimensione secondo le tue esigenze */
    margin: 0 auto;
    position: relative; /* Assicura che gli elementi posizionati assolutamente siano relativi a questo contenitore */
}

/* Stile per le immagini */
.carosello-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

/* Aumenta la dimensione dei puntini di paginazione */
.swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.5); /* Colore scuro per i puntini inattivi */
    opacity: 1; /* Assicura che i puntini siano completamente visibili */
    margin: 0 6px !important; /* Spaziatura tra i puntini */
    transition: background-color 0.3s, transform 0.3s;
}

/* Stile per il puntino attivo */
.swiper-pagination-bullet-active {
    background-color: rgb(232, 71, 28) !important; /* Colore vivace per il puntino attivo */
    transform: scale(1.3); /* Ingrandisce il puntino attivo */
}

/* Stile per i pulsanti di navigazione */
.swiper-button-prev,
.swiper-button-next {
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.3s;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    color: #fff;
}

/* Nascondi i pulsanti di navigazione su dispositivi mobili */
@media (max-width: 768px) {
    .swiper-button-prev,
    .swiper-button-next {
        width: 30px; /* Dimensione ridotta delle frecce */
        height: 30px;
    }

    /* Riduci le icone interne delle frecce */
    .swiper-button-prev::after,
    .swiper-button-next::after {
        font-size: 1.5rem; /* Riduci la dimensione dell'icona della freccia */
    }
    /* Rimpicciolisci anche i puntini sui dispositivi mobili */
    .swiper-pagination-bullet {
        width: 12px; /* Dimensione ridotta per i dispositivi mobili */
        height: 12px;
        margin: 0 4px !important; /* Riduci la spaziatura se necessario */
    }

    .swiper-pagination-bullet-active {
        transform: scale(1.2); /* Regola l'ingrandimento per il puntino attivo */
    }
}

/* Carosello.css */

/* Animazione di zoom in */
@keyframes zoomIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.animate-zoomIn {
    animation: zoomIn 0.3s ease-out forwards;
}

/* Pulsante di chiusura personalizzato (opzionale) */
.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    color: rgb(232, 71, 28);
    font-size: 2rem;
    cursor: pointer;
    z-index: 1001; /* Assicurati che sia sopra l'immagine */
}
