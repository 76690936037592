@keyframes rotate {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(720deg); }
    100% { transform: rotate(720deg); }
}

@keyframes color-inversion {
    0%, 50% { filter: invert(0); }
    100% { filter: invert(1); }
}

@keyframes bg-color-change {
    0%, 50% { background-color: white; }
    100% { background-color: black; }
}

@keyframes fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.init-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
    animation: bg-color-change 4s forwards;
}

.init-homepage{
    animation: fade-out 1s 3s forwards;
}

.logo-animation {
    width: 10rem;
    height: 10rem;
    animation:
            rotate 3s ease-in-out,
            color-inversion 3s forwards,
            fade-out 1s 3s forwards;
}

.init-container .logo-animation {
    margin: auto;
}

.completed {
    display: none;
}
