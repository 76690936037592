@keyframes fadeIn {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}

.fade-in::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1); /* Overlay leggero */
    animation: fadeIn 1.5s ease-in-out forwards;
}

.fade-in {
    animation: fadeIn 1.5s ease-in-out forwards;
}
