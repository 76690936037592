@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Lora:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



*::-webkit-scrollbar {
    width: 20px;
    background:transparent;
}

*::-webkit-scrollbar-track {
    background-color: #000000;
}

*::-webkit-scrollbar-thumb {
    background-image: url('./img/scrollbar.svg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100%, 100%;
}

/* For Firefox */
* {
    scrollbar-width: none; /* "auto" or "thin" */
}


@layer components {
    .custom-underline {
        @apply text-white relative;
        overflow: hidden;
        transition: color 0.3s;

&::after {
     content: '';
     position: absolute;

     width: 0;
     height: 2px;
     bottom: -1px;
     left: 0;
     background-color: white;
     transition: width 0.3s;
 }

&:hover::after {
     width: 100%;
 }
}
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
    filter: invert(100%) hue-rotate(180deg) brightness(60%) contrast(99%) saturate(0%);
}

